import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { routes } from '@/utils/routes';

import Layout from '@/components/Layout';
import Content from '@/components/Content';

import { slugify } from '@/utils/url';

const CategoryTemplate = ({ pageContext: { category, products } }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const translatedRoutes = routes(language);

  useEffect(() => {
    i18n.changeLanguage(`en`);
  }, []);

  const [sortedProducts, setSortedProducts] = useState(null);

  const sortProducts = (product1, product2) => {
    if (product1.name < product2.name) {
      return -1;
    }
    if (product1.name > product2.name) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    setSortedProducts(products.sort(sortProducts));
  }, []);

  return (
    <Layout
      title={`Alimentation pendant la grossesse ? Découvrez quels aliments manger dans la catégorie ${category.name}`}
      description={`Alimentation de la femme enceinte grâce à La fraise. Découvrez tous les aliments dans la catégorie ${category.name} interdits pendant la grossesse ? Télécharger gratuitement notre application mobile La fraise, manger enceinte.`}
    >
      <Content>
        <Link
          to={translatedRoutes.categories}
          className="underline inline-block mb-16"
        >
          {`<- ${t(`backCategories`)}`}
        </Link>

        <div>
          {!!sortedProducts &&
            sortedProducts.map((product) => (
              <div key={`${product.name}`} className="mb-2">
                <Link
                  to={`${translatedRoutes.products}/${slugify(product.name)}`}
                  className="inline-block underlineStyle"
                >
                  {product.name}
                </Link>
              </div>
            ))}
        </div>
      </Content>
    </Layout>
  );
};

export default CategoryTemplate;

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["general"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
